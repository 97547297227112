<template>
    <div class="tcm zhong">
        <hd-com></hd-com>
        <div class="banner">
            <div class="banner-text">
                <div>
                    <h3>中医诊断</h3>
                    <p>ZHONG YI ZHEN DUAN</p>
                </div>
                <div class="banner-right">
                    <span @click="$router.go(-1);">再次测试</span>
                </div>
            </div>
        </div>
        <div class="main-div">
            <div class="detail-box">
                <div v-if="classify == 'tcm'">
                    <div class="detail-item" v-if="TCMDetail.look != ''">
                        <div class="detail-tit" data-size="16">望诊</div>
                        <div class="detail-mes" v-for="(detail, i) in TCMDetail.look" :key="i">
                            <div v-for="(detail1, i1) in title.look" :key="i1">
                                <div v-if="detail1.value == detail.categoryId" class="looktitle" data-size="16">{{ i + 1 }}、{{ detail1.nameStr }}</div>
                            </div>
                            <p data-size="14" v-html="detail.content1.replace(/\n/g, '</p> <p>')"></p>
                        </div>
                    </div>
                    <div class="detail-item" v-if="TCMDetail.hear != ''">
                        <div class="detail-tit" data-size="16">闻诊</div>
                        <div class="detail-mes" v-for="(detail, i) in TCMDetail.hear" :key="i">
                            <div v-for="(detail1, i1) in title.hear" :key="i1">
                                <div v-if="detail1.value == detail.categoryId" class="looktitle" data-size="16">{{ i + 1 }}、{{ detail1.nameStr }}</div>
                            </div>
                            <!-- <div class="looktitle" data-size="16">{{ i + 1 }}、{{ title.hear[i].nameStr }}</div> -->
                            <p data-size="14" v-html="detail.content1.replace(/\n/g, '</p> <p>')"></p>
                        </div>
                    </div>
                    <div class="detail-item" v-if="TCMDetail.ask != ''">
                        <div class="detail-tit" data-size="16">问诊</div>
                        <div class="detail-mes" v-for="(detail, i) in TCMDetail.ask" :key="i">
                            <div v-for="(detail1, i1) in title.ask" :key="i1">
                                <div v-if="detail1.value == detail.categoryId" class="looktitle" data-size="16">{{ i + 1 }}、{{ detail1.nameStr }}</div>
                            </div>
                            <!-- <div class="looktitle" data-size="16">{{ i + 1 }}、{{ title.ask[i].nameStr }}</div> -->
                            <p data-size="14" v-html="detail.content1.replace(/\n/g, '</p> <p>')"></p>
                        </div>
                    </div>
                    <div class="detail-item" v-if="TCMDetail.pulse != ''">
                        <div class="detail-tit" data-size="16">切诊</div>
                        <div class="detail-mes" v-for="(detail, i) in TCMDetail.pulse" :key="i">
                            <div v-for="(detail1, i1) in title.pulse" :key="i1">
                                <div v-if="detail1.value == detail.categoryId" class="looktitle" data-size="16">{{ i + 1 }}、{{ detail1.nameStr }}</div>
                            </div>
                            <!-- <div class="looktitle" data-size="16">{{ i + 1 }}、{{ title.puls[i].nameStr }}</div> -->
                            <p data-size="14" v-html="detail.content1.replace(/\n/g, '</p> <p>')"></p>
                        </div>
                    </div>
                </div>
                <div v-if="classify == 'diff'">
                    <div class="detail-item" v-for="(detail, i) in diffDetail" :key="i" v-if="TCMDetail != ''">
                        <div class="detail-mes">
                            <div v-for="(detail1, i1) in title.Diff" :key="i1">
                                <div v-if="detail1.value == detail.categoryId" class="looktitle" data-size="16">{{ i + 1 }}、{{ detail1.nameStr }}</div>
                            </div>
                            <!-- <div class="looktitle" data-size="16">{{ i + 1 }}、{{ title.Diff[i].nameStr }}</div> -->
                            <div class="detail-info">
                                <p data-size="14" v-html="detail.content1.replace(/\n/g, '</p> <p>')"></p>
                            </div>
                        </div>
                        <div class="">
                            <div class="detail-tit" data-size="16">治法方剂</div>
                            <div class="hxsw" v-if="detail.herbal != null && detail.herbal.length != 0">
                                <span v-for="(herbalItem, i) in detail.herbal" :key="i"
                                    @click="toHerbalDetail(herbalItem.hashKey)">
                                    {{ herbalItem.herbalPrescriptionName }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
import hdCom from '../../components/header' //引入组件
import ftCom from '../../components/footer' //引入组件
// import rtBar from "../../components/right";
import { Differentiation } from "../../components/TCMDomain/Differentiation";
import { TCMDiagnosis } from "../../components/TCMDomain/TCMDiagnosis";
import { toHerbal } from "../../utils/relation";
import { DiseaseUtility, replaceContent, checkNameAndPath, toSearchList, toDetailInfo } from "../../utils/diseaseUtility";
import httpdata from '../../../public/services.json'
var http = httpdata

export default {
    name: "tcmDiagnosis",
    components: {
        hdCom,
        ftCom,
        // rtBar
    },
    data() {
        var differentiation = new Differentiation(this.TokenClient, this.Services.TCM);
        var tcmDiagnosis = new TCMDiagnosis(this.TokenClient, this.Services.TCM);
        var diseaseUtility = new DiseaseUtility(this.TokenClient, this.Services)
        return {
            diseaseUtilityDomain: diseaseUtility,
            DifferentDomain: differentiation,
            TCMDomain: tcmDiagnosis,
            params: '',
            TCMDetail: {},
            diffDetail: {},
            classify: '',
            title: {},
        }
    },
    mounted() {
        this.params = this.$route.query.params;
        this.classify = this.$route.query.class;
        this.title = JSON.parse(this.$route.query.title)
        console.log(this.title)
        if (this.classify == 'diff') {
            this.getdiffDetail();
        } else {
            this.getTcmDetail();
        }
    },
    methods: {
        getTcmDetail() {
            var _this = this;
            _this.TCMDomain.Detail(this.params, function (data) {
                _this.TCMDetail = data.data;
                for (let i = 0; i < _this.TCMDetail.ask.length; i++) {
                    _this.TCMDetail.ask[i].content1 = replaceContent(_this.TCMDetail.ask[i].content)

                }
                for (let i = 0; i < _this.TCMDetail.hear.length; i++) {
                    _this.TCMDetail.hear[i].content1 = replaceContent(_this.TCMDetail.hear[i].content)

                }
                for (let i = 0; i < _this.TCMDetail.look.length; i++) {
                    _this.TCMDetail.look[i].content1 = replaceContent(_this.TCMDetail.look[i].content)
                }
                for (let i = 0; i < _this.TCMDetail.pulse.length; i++) {
                    _this.TCMDetail.pulse[i].content1 = replaceContent(_this.TCMDetail.pulse[i].content)

                }
            },
                function (error) {
                    console.log('中医诊断请求异常!请刷新重试。', error)
                })
        },
        getdiffDetail() {
            var _this = this;
            _this.DifferentDomain.Detail(_this.params, function (data) {
                _this.diffDetail = data.data;
                for (let i = 0; i < _this.diffDetail.length; i++) {
                    _this.diffDetail[i].content1 = replaceContent(_this.diffDetail[i].content)
                    _this.$set(_this.diffDetail[i], 'text', _this.title.Diff.filter(k => _this
                        .diffDetail[i].categoryId == k.value)[0].text)
                }
                _this.$bus.$emit('fontSize');
            },
                function (error) {
                    console.log('辨证方法请求异常!请刷新重试。', error)
                })

        },
        //跳转同类药-中药方剂详情
        toHerbalDetail(hashKey) {
            toHerbal(hashKey, 0, '');
        },
        //辨证方法关联中药方剂
        math: function (info) {
            return replaceContent(info);
        },
        //跳转疾病详情
        getPageInfo(name, type, hashKey) {
            if (hashKey == 'Search') {
                toSearchList(checkNameAndPath(type).typeName, name)
            } else {
                toDetailInfo(checkNameAndPath(type).path, hashKey, type)
            }
        },

    },
    created() {
        let _this = this;
        window.getPageInfo = _this.getPageInfo;
        window.toHerbalDetail = _this.toHerbalDetail;
    },
}
</script>

<style scoped>
.tcm {
    height: auto;
    background: #F1F1F1;
}

.zhong .banner {
    height: 116px;
    background: url("../../assets/img/jbk_banner_zy.jpg")center no-repeat;
}

.banner-right {
    position: absolute;
    right: 5px;
    top: 45px;
}

.banner-right span {
    display: inline-block;
    margin-left: 20px;
    width: 132px;
    height: 32px;
    border: 1px solid #ffffff;
    border-radius: 32px;
    box-sizing: border-box;
    text-align: center;
    color: #ffffff;
    line-height: 30px;
    cursor: pointer;
}

.banner-right span:hover {
    background-color: #ffffff;
    color: #00ab98;
}

.zhong .banner-right span:hover {
    background-color: #ffffff;
    color: #b76c12;
}

.banner-text {
    width: 1200px;
    margin: 0 auto;
    position: relative;
}

.banner-text h3 {
    padding-top: 25px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text p {
    font-size: 18px;
    color: #ffffff;
}

.main-div {
    width: 1300px;
    margin: 20px auto;
    border-radius: 8px;
    background-color: #fff;
    min-height: 430px;
    padding: 10px 20px;
}

.detail-item {
    margin-bottom: 20rpx;
}

.detail-item .detail-tit {
    display: inline-block;
    color: #b76c12;
    line-height: 1;
    font-size: 15px;
    /* border-bottom: 2px solid #B76C12; */
    margin: 5px 0 0;
}

.detail-item .detail-info {
    font-size: 28rpx;
    text-indent: 56rpx;
}

.hxsw span {
    display: inline-block;
    padding: 5px 15px;
    margin: 5px 12px 5px 0;
    border-radius: 2px;
    font-size: 14px;
    color: #B76C12;
    background-color: #FFFFFF;
    border: 1px solid #B76C12;
    cursor: pointer;
}

.looktitle {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
}


.hxsw li {
    margin: 5px 0;
    display: inline-block;
    height: 42px;
    /* border: 1px solid #999999; */
    padding-right: 10px;
    box-sizing: border-box;
}

.hxsw li a {
    line-height: 42px;
    text-align: center;
    color: #666666;
    font-size: 16px;
    cursor: pointer;
}

.hxsw li:hover {
    border-color: #b76c12;
}

.hxsw li:hover a {
    color: #b76c12;
}

.dialectical {
    margin: 30px 0;
}


.hxsw {
    margin: 0px 0 5px;
}
</style>
